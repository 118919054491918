<template>
  <div>
    <v-menu
      v-model="showMenu"
      :close-on-click="true"
      :close-on-content-click="true"
      dark
      right
      offset-x
      nudge-left="40"
      class="_menu"
      eager
    >
      <template v-slot:activator="{ on }">
        <div
          @click="$emit('folder-clicked', true)"
          class="parent ma-3"
          @click.right.prevent="showMenu=true"
        >
          <v-icon x-large class="_folder">folder</v-icon>
          <p>{{properties.name}}</p>
        </div>
      </template>
      <v-list dense>
        <v-list-item @click="$emit('folder-clicked', true)">
          <v-list-item-title>Open</v-list-item-title>
        </v-list-item>
        <v-divider dark></v-divider>
        <v-list-item>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-divider dark></v-divider>
        <v-list-item @click="$emit('delete-folder-event',properties)">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <div class="parent ma-3" @click.right.prevent="showMenu=true">
      <v-icon x-large class="_folder">folder</v-icon>
      <p>{{properties.name}}</p>
    </div>-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      showMenu: false
    }
  },
  props: {
    properties: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.parent {
  text-align: center;
  p {
    word-wrap: break-word;
  }
}
._folder {
  font-size: 80px !important;
}
._folder:hover {
  cursor: pointer;
  color: var(--v-primary-base);
}
._folder-selected {
  color: var(--v-primary-base);
}
.v-list {
  opacity: 0.8 !important;
}
</style>
