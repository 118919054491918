import axios from 'axios'
const URL = process.env.VUE_APP_UNOAPP_RESOURCES_API_URL

export const API = {
  Folder: {
    getAllFoldersInRootDirectory (headers) {
      return axios.get(`${URL}/folders`, {
        headers: headers
      })
    },
    getResourcesInAFolder (folder, headers) {
      return axios.get(`${URL}/folders/${folder}/resources`, {
        headers: headers
      })
    },
    createNewFolder (data, headers) {
      return axios.post(`${URL}/folders`, data, {
        headers: headers
      })
    },
    getFolderById (folder, headers) {
      return axios.get(`${URL}/folders/${folder}`, {
        headers: headers
      })
    },
    delete (folder, headers) {
      return axios.delete(`${URL}/folders/${folder}`, {
        headers: headers
      })
    }
  },
  Resource: {
    // create the presigned URL
    createResource (data, headers) {
      return axios.post(
        `${URL}/resources/upload`,
        data, {
          headers: headers
        }
      )
    },
    getRootResources (headers) {
      return axios.get(`${URL}/resources`, {
        headers: headers
      })
    },
    getRootResourcesPaginated (page, headers) {
      return axios.get(`${URL}/resources?page=${page}&limit=15`, {
        headers: headers
      })
    },
    getFolderResource (folder, headers) {
      return axios.get(`${URL}/resources/folders/${folder}`, {
        headers: headers
      })
    },
    getFolderResourcePaginated (folder, page, headers) {
      return axios.get(`${URL}/resources/folders/${folder}?page=${page}&limit=15`, {
        headers: headers
      })
    },
    uploadResource (presignedURL, file, config) {
      return axios.put(presignedURL, file, config)
    },
    delete (res, headers) {
      return axios.delete(`${URL}/resources/${res}`, {
        headers: headers
      })
    }
  }
}
