<template>
  <div>
    <div
      id="drop-area"
      @dragenter.prevent="changeStyleToActive"
      @dragover.prevent="changeStyleToActive"
      @dragleave.prevent="changeStyleToInactive"
      @drop.prevent="addFiles"
      :class="{'drag-active': dragging}"
    >
      <form class="my-form">
        <v-layout row wrap justify-center>
          <p>Drag files here to upload</p>
          <v-flex xs12>
            <input type="file" id="fileElem" multiple @change="inputChange" />
          </v-flex>
          <v-btn tag="label" text color="primary" for="fileElem">Click to select files</v-btn>
        </v-layout>
      </form>
    </div>
    <v-layout row wrap ma-2 mt-5>
      <v-flex xs12 sm6 md4 v-for="(file, index) in filesData" :key="index" class="pa-1">
        <v-card elevation="5" outlined>
          <v-progress-linear
            v-if="file.upload.started"
            :value="file.upload.value"
            :color="file.upload.completed?'success':'primary'"
          ></v-progress-linear>
          <v-card-text>
            <v-card-title class="pa-0">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-icon :color="hover? 'primary': ''" @click="removeFile(file, index)">close</v-icon>
              </v-hover>
            </v-card-title>
            <v-container>
              <v-layout row wrap justify-center>
                <v-flex xs12 v-if="isImage(file.file)">
                  <v-img
                    :src="file.localURL"
                    aspect-ratio="3:2"
                    class="grey lighten-2"
                    max-width="500"
                    max-height="200"
                  ></v-img>
                </v-flex>
                <v-flex class="text-center" align-center xs12 v-else-if="isPDF(file.file)">
                  <v-icon size="110">far fa-file-pdf</v-icon>
                </v-flex>
                <v-flex class="text-center" align-center xs12 v-else>
                  <v-icon size="110">attachment</v-icon>
                </v-flex>
                <v-text-field v-model="file.name" label="Name*" required></v-text-field>
                <v-text-field v-model="file.description" label="Description" required></v-text-field>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { API } from '../controller.resources'

export default {
  beforeDestroy () {
    this.dragging = false
    this.filesData = []
  },
  props: {
    folder: {
      required: true
    },
    headers: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      dragging: false,
      filesData: []
    }
  },
  methods: {
    updateSpinner () {
      this.$emit('update-spinner')
    },
    changeStyleToActive () {
      this.dragging = true
    },
    changeStyleToInactive () {
      this.dragging = false
    },
    removeFile (file, index) {
      this.filesData.splice(index, 1)
    },
    addFiles (e) {
      let fileList = e.dataTransfer.files || e.target.files
      for (const file of fileList) {
        // get data to render to user
        let fileData = this.getFileData(file, fileData => {
          this.filesData.push(fileData)
        })
      }
      this.dragging = false
    },
    inputChange (e) {
      for (const file of e.target.files) {
        // get data to render to user
        let fileData = this.getFileData(file, fileData => {
          this.filesData.push(fileData)
        })
      }
    },
    onUploadProgress (progressEvent, item) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      item.upload.value = percentCompleted
      return percentCompleted
    },
    promisifyUploadData (uploadData, file, headers) {
      let that = this
      return API.Resource.createResource(uploadData, headers)
        .then(res => {
          let uploadURL = res.data.payload.upload_url
          let config = {
            headers: {
              'Content-Type': file.file.type
            },
            onUploadProgress: event => {
              that.onUploadProgress(event, file)
            }
          }
          return API.Resource.uploadResource(uploadURL, file.file, config)
        })
        .then(res2 => {
          file.upload.completed = true
        })
    },
    uploadFiles () {
      this.updateSpinner()
      let that = this
      // map all the promises to emit one single events when all files are uploaded
      let allPromises = that.filesData.map(file => {
        file.upload.started = true
        // get the file to upload
        let fileToUpload = file.file
        // prep data to get presigned URL
        let uploadData = {
          name: file.name,
          content_type: fileToUpload.type,
          desc: '',
          tags: []
        }
        if (that.folder !== null && that.folder.id) {
          uploadData.folder_id = that.folder.id
        }
        // check if description , if so add to data

        if (file.description && file.description !== '') {
          uploadData.description = file.description
        }
        return that.promisifyUploadData(uploadData, file, that.headers)
      })
      Promise.all(allPromises)
        .then(s => {
          that.$emit('files-uploaded')
          this.updateSpinner()
        })
        .catch(e => {
          console.log('ERROR!', e)
          this.updateSpinner()
        })
    },
    getFileData (file, callback) {
      this.getFileURL(file, url => {
        let fileData = {
          localURL: url,
          name: file.name,
          description: '',
          upload: {
            started: false,
            completed: false,
            value: 0
          },
          file
        }
        callback(fileData)
      })
    },
    getFileURL (file, callback) {
      if (!this.isImage(file)) {
        return callback('')
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        let result = reader.result
        callback(result)
      }
    },
    isImage (file) {
      return file.type.startsWith('image/')
    },
    isPDF (file) {
      return file.type === 'application/pdf'
    },
    isArchive (file) {
      return file.type === 'application/zip'
    },
    isHTML (file) {
      return file.type === 'text/html'
    },
    resetState () {
      console.log('here')
      this.dragging = false
      this.filesData = []
    }
  }
}
</script>

<style lang="scss" scoped>
#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 100%;
  font-family: sans-serif;
  padding: 20px;
}
.drag-active {
  border-color: var(--v-primary-base);
  background: var(--v-secondary-base);
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

#fileElem {
  display: none;
}
</style>
