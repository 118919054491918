<template>
  <div>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="true"
      dark
      right
      offset-x
      nudge-left="40"
      class="_menu"
      max-width="100px"
      eager
    >
      <template v-slot:activator="{ on }">
        <v-layout row wrap justify-center ma-2 pa-2 @click.right.prevent="showMenu=true">
          <v-flex xs12 @click.prevent.stop="selectResource" is-relative>
            <div class="image-wrapper text-center" v-if="isImage" :class="{'selected': selected}">
              <v-img
                :src="`${properties.full_url}?d=250x250`"
                lazy-src="https://via.placeholder.com/150"
                class="img-responsive grey lighten-2"
                aspect-ratio="1.5"
                position="center"
                @error="imageLoadError($event, properties.full_url)"
              ></v-img>
            </div>
            <div v-else class="text-center text-truncate image-wrapper">
              <v-icon size="110">attachment</v-icon>
            </div>
            <v-icon v-if="selected" class="selected-icon">check_circle</v-icon>
          </v-flex>
          <v-flex xs12 class="text-center text-truncate">
            <p class="caption mt-4" :class="{'white--text': dark}">{{properties.name}}</p>
          </v-flex>
        </v-layout>
      </template>
      <v-list dense>
        <v-list-item>
          <v-list-item-title>View</v-list-item-title>
        </v-list-item>
        <v-divider dark></v-divider>
        <v-list-item @click="$emit('delete-resource-event', properties)">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showMenu: false
    }
  },
  props: {
    properties: {
      type: Object
    },
    dark: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectResource () {
      if (this.selected) {
        return this.$emit('remove-selected-resource', this.properties)
      }
      return this.$emit('select-resource', this.properties)
    },
    imageLoadError (error, url) {
      console.log(error)
      this.properties.full_url = url
    }
  },
  computed: {
    isImage () {
      return this.properties.content_type.startsWith('image')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative !important;
  border: 1px solid black !important;
  width: 100% !important;
}

.image-wrapper:hover {
  cursor: pointer;
  border: 2px solid var(--v-primary-base) !important;
}
.selected {
  border: 3px solid var(--v-primary-base) !important;
}

.selected-icon {
  position: absolute !important;
  top: 5px !important;
  left: 10px !important;
  color: var(--v-success-base) !important;
  opacity: 1 !important !important;
  border: 2px solid white !important;
  border-radius: 50% !important;
}

.is-relative {
  position: relative !important;
}
</style>
