<template>
  <div class="h-100 is-relative">
    <v-overlay v-if="spinnner" :value="spinnner" z-index="99999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="h-100">
      <v-toolbar dark color="primary">
        <v-btn icon fab @click="$emit('closeModal', false)"><v-icon>cancel</v-icon></v-btn>
        <v-toolbar-title>Resources Explorer</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-btn
            depressed
            class="mx-2"
            fab
            dark
            small
            color="primary"
            @click.prevent="loadRootFolder"
          >
            <v-icon dark>home</v-icon>
          </v-btn>
          <span v-for="item in folderStack" :key="item._id">
            <v-btn text small @click.prevent="spliceFolderStack(item)">{{item.name}}</v-btn>/
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- MENU START -->
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon dark v-on="on">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-list dense flat>
              <v-subheader>CREATE</v-subheader>
              <v-list-item @click="dialogs.newFolder=true">
                <v-list-item-icon>
                  <v-icon>create_new_folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>New Folder</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <!-- TODO: Add a water drop icon to resource -->
              <v-list-item @click="dialogs.newResource = true">
                <v-list-item-icon>
                  <v-icon>attach_file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>New Resource</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- MENU END -->
        </v-toolbar-items>
      </v-toolbar>
      <v-navigation-drawer
        v-if="resourcesList.length > 0"
        fixed
        right
        app
        hide-overlay
        disable-resize-watcher
        dark
      >
        <v-layout row wrap justify-center ma-5>
          <v-btn color="primary" @click="confirmSelectedResources">Confirm Selection</v-btn>
        </v-layout>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-col cols="10" class="pa-0 ma-0" v-for="(resource,index) in resourcesList" :key="index">
            <app-resource
              :dark="true"
              @select-resource="resourceSelected"
              @remove-selected-resource="removeSelectedResource"
              @delete-resource-event="resourceDeleteEvent"
              :properties="resource"
              :selected="isResourceSelected(resource)"
            ></app-resource>
          </v-col>
        </v-row>
      </v-navigation-drawer>
      <v-row align="stretch" class="is-relative">
        <v-col cols="12">
          <!-- Folders -->
          <v-layout row wrap v-if="currentDirectory.folders.length > 0" mx-5>
            <v-flex xs4 md1 sm2 v-for="folder in currentDirectory.folders" :key="folder._id">
              <app-folder
                @delete-folder-event="folderDeleteEvent"
                @folder-clicked="selectFolder(folder)"
                :properties="folder"
              ></app-folder>
            </v-flex>
          </v-layout>
          <v-layout v-else row wrap justify-center>
            <p class="mt-10 mb-10">
              No folders found. Create one by clicking on the
              <v-icon class="ml-3 mr-3" color="primary" size="30">add</v-icon>icon from the top bar.
            </p>
          </v-layout>
          <v-divider class="ml-3 mr-5 pa-5"></v-divider>
          <!-- Files -->
          <v-layout v-if="currentDirectory.resources.length > 0" row wrap class="ma-3">
            <v-flex xs4 md2 sm2 v-for="resource in currentDirectory.resources" :key="resource.id">
              <app-resource
                class="pa-4"
                @select-resource="resourceSelected"
                @remove-selected-resource="removeSelectedResource"
                @delete-resource-event="resourceDeleteEvent"
                :properties="resource"
                :selected="isResourceSelected(resource)"
              ></app-resource>
            </v-flex>
            <v-pagination
              @input="goToPage"
              @next="nextPage"
              @previous="previousPage"
              v-model="pagination.page"
              :length="pagination.length"
            ></v-pagination>
          </v-layout>
          <v-layout v-else row wrap justify-center>
            <p class="mt-10 mb-10">
              No Resources found. Create one by clicking on the
              <v-icon class="ml-3 mr-3" color="primary" size="30">add</v-icon>icon from the top bar.
            </p>
          </v-layout>
          <!-- <v-layout
            v-if="currentDirectory.Resources && currentDirectory.Resources.length > 0"
            row
            wrap
            class="ma-3"
          >
            <v-flex xs12 sm2 v-for="resource in currentDirectory.Resources" :key="resource._id">
              <app-resource
                @select-resource="resourceSelected"
                @remove-selected-resource="removeSelectedResource"
                @delete-resource-event="resourceDeleteEvent"
                :properties="resource"
                :selected="isResourceSelected(resource)"
              ></app-resource>
            </v-flex>
          </v-layout>-->
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-if="dialogs.newFolder" v-model="dialogs.newFolder" max-width="600px">
      <v-card>
        <v-toolbar dark color="gray">
          <v-toolbar-title>New Folder</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialogs.newFolder = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="newFolder.name" label="Name*" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="newFolder.description" label="Description" type="text"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.newFolder = false">Close</v-btn>
          <v-btn text @click="createNewFolder">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Upload Resource Dialog -->
    <v-dialog
      v-if="dialogs.newResource"
      v-model="dialogs.newResource"
      max-width="800px"
      @click:outside="doneUploading"
    >
      <v-card>
        <v-toolbar dark color="gray">
          <v-toolbar-title>
            Add a resource to
            <span
              v-if="currentDirectory.folderDetails"
              class="text-uppercase primary--text"
            >{{currentDirectory.folderDetails.name}}</span>
            <span v-else class="text-uppercase primary--text">ROOT</span> folder
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="doneUploading">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <app-upload
              @update-spinnner="spinnner = !spinnner"
              class="mx-4"
              ref="app-upload-component"
              :folder="currentDirectory.folderDetails"
              :headers="getHeaders"
              @files-uploaded="filesUploaded"
            ></app-upload>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="dialogs.newFolder = false">Close</v-btn> -->
          <v-btn v-if="!uploaded" text @click="uploadFiles">Confirm</v-btn>
          <div v-else>
            <v-btn text @click="uploadMore">Upload More</v-btn>
            <v-btn text color="primary" @click="doneUploading">Done</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Dialog -->
    <v-dialog
      v-if="dialogs.delete.dialog"
      v-model="dialogs.delete.dialog"
      max-width="600px"
      absolute
    >
      <v-card>
        <v-toolbar color="danger" dark>
          <v-toolbar-title>
            Are you sure you want to permanently delete
            <span
              class="text-uppercase bloodred--text"
            >{{dialogs.delete.item.name}}</span>?
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialogs.delete.dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout row wrap justify-center>
              <v-flex xs12 v-if="!dialogs.delete.item.content_type">
                <p class="body-1 text-center">
                  All the items inside the folder
                  <span
                    class="ml-1 mr-1 bloodred--text font-weight-bold"
                  >won't</span> be accessible. Are you sure you want to continue?
                </p>
              </v-flex>
              <v-flex xs6>
                <app-resource
                  v-if="dialogs.delete.item.content_type"
                  :properties="dialogs.delete.item"
                ></app-resource>
                <app-folder v-else :properties="dialogs.delete.item"></app-folder>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.delete.dialog = false">Close</v-btn>
          <v-btn text color="error" @click="deleteItems">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AppFolder from './custom-components/app.folder'
import AppResource from './custom-components/app.resource'
import AppUpload from './custom-components/app.upload'
import { API } from './controller.resources'

export default {
  mounted () {
    this.loadRootFolder()
  },
  components: {
    AppFolder,
    AppResource,
    AppUpload
  },
  props: {
    apiToken: {
      type: String,
      required: true
    },
    authToken: {
      type: String,
      required: true
    },
    allowMultiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 0,
      pagination: {
        length: 1,
        page: 1,
        perPage: 25
      },
      // dialog togglers
      dialogs: {
        main: false,
        newFolder: false,
        newResource: false,
        delete: {
          dialog: false,
          item: ''
        }
      },
      // to render folders/files in current directory
      currentDirectory: {
        folderDetails: null,
        folders: [],
        resources: '',
        loading: false
      },
      // new folder modal
      newFolder: {
        name: '',
        description: ''
      },
      root: '',
      // directory navigation on the top bar
      folderStack: [],
      // upload component togglers
      uploaded: false,
      // resources properties
      resourcesList: [],
      sheet: true,
      headers: [
        {
          text: 'Resource',
          align: 'start',
          sortable: false,
          value: 'full_url'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'file_type'
        },
        {
          text: 'Updated By',
          align: 'start',
          sortable: false,
          value: 'updated_by'
        },
        {
          text: 'Action',
          align: 'end',
          sortable: false,
          value: 'action'
        }
      ],
      spinnner: false
    }
  },
  methods: {
    loadRootFolder () {
      this.pagination = {
        length: 1,
        page: 1,
        perPage: 25
      }
      this.spinnner = true
      this.folderStack = []
      this.currentDirectory = {
        folderDetails: null,
        folders: [],
        resources: '',
        loading: false
      }
      API.Folder.getAllFoldersInRootDirectory(this.getHeaders)
        .then(res => {
          this.currentDirectory.folders = res.data.payload.folders
          this.spinnner = false
        }).catch((e) => {
          console.log(e)
          this.spinnner = false
        })
      API.Resource.getRootResourcesPaginated(this.pagination.page, this.getHeaders).then(s => {
        this.currentDirectory.resources = s.data.payload.resources
        this.pagination.length = s.data.payload.total_pages
        this.pagination.page = s.data.payload.current_page
        this.spinnner = false
      }).catch((e) => {
        console.log(e)
        this.spinnner = false
      })
    },
    loadResources (folderId) {
      return API.Folder.getResourcesInAFolder(folderId, this.getHeaders)
    },
    createNewFolder () {
      this.spinnner = true
      let data = {
        name: this.newFolder.name,
        description: this.newFolder.description
      }
      if (this.currentDirectory.folderDetails !== null && this.currentDirectory.folderDetails.id) {
        data.parent_id = this.currentDirectory.folderDetails.id
      }
      API.Folder.createNewFolder(data, this.getHeaders).then(s => {
        this.currentDirectory.folders.push(s.data.payload)
        this.newFolder.name = ''
        this.newFolder.description = ''
        this.dialogs.newFolder = false
        this.spinnner = false
      }).catch(e => {
        console.log(e)
        this.spinnner = false
      })
    },
    async selectFolder (folder) {
      this.pagination = {
        length: 1,
        page: 1,
        perPage: 25
      }
      this.spinnner = true
      API.Folder.getFolderById(folder.id, this.getHeaders).then(s => {
        this.currentDirectory.folders = s.data.payload.folders
        this.currentDirectory.folderDetails = folder
        this.folderStack.push(folder)
        this.spinnner = false
      }).catch(e => {
        if (e.response.status === 404) {
          this.currentDirectory.folderDetails = folder
          this.currentDirectory.folders = []
          this.folderStack.push(folder)
        }
        // TODO Handle 404
        console.log(e)
        this.spinnner = false
      })
      this.spinnner = true
      API.Resource.getFolderResourcePaginated(folder.id, this.pagination.page, this.getHeaders).then(s => {
        this.currentDirectory.resources = s.data.payload.resources
        this.pagination.length = s.data.payload.total_pages
        this.pagination.page = s.data.payload.current_page
        this.spinnner = false
      }).catch(e => {
        if (e.response.status === 404) {
          this.currentDirectory.resources = []
        }
        this.spinnner = false
        // TODO Handle 404
        console.log(e)
      })
    },
    spliceFolderStack (folder) {
      let index = this.folderStack.findIndex(item => item.id === folder.id)
      if (index === -1) {
        return // TODO: with an error
      }
      this.folderStack.splice(index)
      this.selectFolder(folder)
    },
    // upload-component functions
    uploadFiles () {
      this.$refs['app-upload-component'].uploadFiles()
    },
    filesUploaded () {
      this.uploaded = true
    },
    closeNewResourceDialog () {
      this.dialogs.newResource = false
      this.clearUploadState()
    },
    clearUploadState () {
      this.$refs['app-upload-component'].resetState()
    },
    uploadMore () {
      this.clearUploadState()
      this.uploaded = false
    },
    async doneUploading () {
      if (this.currentDirectory.folderDetails === null) {
        this.loadRootFolder()
      } else {
        await this.spliceFolderStack(this.currentDirectory.folderDetails)
      }
      this.closeNewResourceDialog()
      this.uploadMore()
    },
    // resources function
    removeSelectedResource (resource) {
      let index = this.resourcesList.findIndex(
        item => item._id === resource._id
      )
      if (index < 0) return
      this.resourcesList.splice(index, 1)
    },
    resourceSelected (resource) {
      let numResources = this.resourcesList.length
      if (this.allowMultiple) {
        this.resourcesList.unshift(resource)
      } else if (numResources < 1) {
        this.resourcesList.unshift(resource)
      }
    },
    isResourceSelected (resource) {
      return (
        this.resourcesList.findIndex(item => item.id === resource.id) > -1
      )
    },
    confirmSelectedResources () {
      this.$emit('resources-selected', this.resourcesList)
      this.resourcesList = []
    },
    // Delete Functions
    folderDeleteEvent (fol) {
      this.dialogs.delete.item = fol
      this.dialogs.delete.dialog = true
    },
    resourceDeleteEvent (res) {
      this.dialogs.delete.item = res
      this.dialogs.delete.dialog = true
    },
    deleteItems () {
      this.spinnner = true
      let item = this.dialogs.delete.item
      if (item.content_type) {
        return API.Resource.delete(item.id, this.getHeaders)
          .then(s => {
            this.goToPage(this.pagination.page)
            this.dialogs.delete.item = ''
            this.dialogs.delete.dialog = false
            this.spinnner = false
          })
          .catch(e => {
            console.log(e)
            this.spinnner = false
          })
      } else {
        return API.Folder.delete(item.id, this.getHeaders)
          .then(s => {
            this.dialogs.delete.item = ''
            this.dialogs.delete.dialog = false
            let index = this.currentDirectory.folders.findIndex(folder => folder.id === item.id)
            console.log(index)
            if (index === -1) {
              return // TODO: with an error
            }
            this.currentDirectory.folders.splice(index, 1)
            this.spinnner = false
          })
          .catch(e => {
            console.log(e)
            this.spinnner = false
          })
      }
    },
    isBottomSheetVisible () {
      let el = document.getElementsByClassName('bottom-sheet')
      if (!el || el.length < 1) {
        return false
      }
      var rect = el[0].getBoundingClientRect()
      var elemTop = rect.top
      var elemBottom = rect.bottom

      // Only completely visible elements return true:
      var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight)
      // Partially visible elements return true:
      // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return isVisible
    },
    goToPage (page) {
      if (this.currentDirectory.folderDetails === null) {
        // load root stuff paginated
        this.spinnner = true
        API.Resource.getRootResourcesPaginated(page, this.getHeaders).then(s => {
          this.currentDirectory.resources = s.data.payload.resources
          this.pagination.length = s.data.payload.total_pages
          this.pagination.page = s.data.payload.current_page
          this.spinnner = false
        }).catch((e) => {
          this.spinnner = false
          console.log(e)
        })
      } else {
        // load other stuff paginated
        this.spinnner = true
        API.Resource.getFolderResourcePaginated(this.currentDirectory.folderDetails.id, page, this.getHeaders).then(s => {
          this.currentDirectory.resources = s.data.payload.resources
          this.pagination.length = s.data.payload.total_pages
          this.pagination.page = s.data.payload.current_page
          this.spinnner = false
        }).catch(e => {
          if (e.response.status === 404) {
            this.currentDirectory.resources = []
          }
          this.spinnner = false
          // TODO Handle 404
          console.log(e)
        })
      }
    },
    nextPage () {
      if (this.pagination.page < this.pagination.length) {
        this.goToPage(this.pagination.page)
      }
    },
    previousPage () {
      if (this.pagination.page >= 1) {
        this.goToPage(this.pagination.page)
      }
    }
  },
  computed: {
    getHeaders () {
      return {
        'api-token': this.apiToken,
        'auth-token': this.authToken
      }
    }
  }
}
</script>
<style scoped lang="scss">
.is-relative {
  position: relative !important;
}
.img-responsive {
  border: 1px solid black !important;
}
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-in {
  height: inherit;
}
.bottom-sheet {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
